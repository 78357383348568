:root {
    --secondary: #f5f8fd;
    --border-outline: #b2cafd;
    --blue: #004ffa;
    --warning: #ff4e00;
    --dark: #171851;
}

.navbar {
    padding: 15px 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: #171851;
}

.k-item {
    color: var(--warning);
    font-size: 13px;
}

.k-state-selected {
    background-color: var(--secondary) !important;
    color: var(--dark) !important;
    font-size: 13px;
}

.headline-text {
    padding-left: 70px;
    position: relative;
    margin-bottom: 20px;
    padding-right: 3em;
    .smiley-face {
        position: absolute;
        left: 0px;
        top: -10px;
        padding: 10px;
        background-color: #fff;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        font-size: 25px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.l-1 {
    line-height: 1;
}

.btn:not(.btn-sm) {
    border-radius: 2px;
    font-size: 12px;
    line-height: 30px;
    text-transform: capitalize;
}

.btn-sm {
    border-radius: 2px;
    font-size: 12px;
    text-align: left;
}

.btn-outline-warning {
    color: var(--warning);
    border-color: var(--warning);
    background-color: transparent;
    &:hover,
    &:focus {
        color: #fff;
        border-color: var(--warning);
        background-color: var(--warning);
    }
}

.btn-warning {
    color: #fff;
    border-color: var(--warning);
    background-color: var(--warning);
    &:hover,
    &:focus {
        color: var(--warning);
        border-color: var(--warning);
        background-color: transparent;
    }
}

a {
    color: var(--blue);
    font-size: 12px;
}

.left-image {
    display: block;
    margin: 0 auto;
    width: 400px;
}

body {
    background-color: var(--secondary);
}

p {
    font-size: 12px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e6f1fd;
    padding: 50px;
    border-radius: 5px;
}

.form-label {
    font-size: 11px;
    font-weight: 500 !important;
    margin: 0;
}

.input-group {
    .input-group-text {
        line-height: 35px;
        padding: 0 10px;
        height: 49px;
        background-color: #eaf1ff;
        border: 1px solid #b2cafd;
        font-size: 10px;
        width: 60px;
        font-weight: 800;
        text-align: center;
        justify-content: center;
        text-transform: uppercase;
    }
}

.form-control {
    border: 1px solid var(--border-outline);
    border-radius: 4px;
    font-size: 13px;
    line-height: 35px;
    margin-bottom: 10px;
    &.fill {
        background-color: #f5f8fd;
    }
}

.spacer {
    height: 30px;
}

.right-scrollable {
    max-height: 80vh;
    overflow-y: auto;
    padding-bottom: 70px;
    // -ms-overflow-style: none;
    // /* IE and Edge */
    // scrollbar-width: none;
    // /* Firefox */
    // &::-webkit-scrollbar {
    //     display: none;
    // }
}

a,
button,
.close-icon {
    cursor: pointer;
}

.stepper-footer {
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    z-index: 999999;
    button {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.stepper-breadcrumb {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .stepper-item {
        display: flex;
        align-items: center;
        &.active {
            .span {
                background-color: #ff4e00;
                color: #fff;
            }
            span+span {
                font-weight: 600;
            }
        }
        .span {
            background-color: #b9b9ca;
            width: 15px;
            height: 15px;
            font-size: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 5px;
        }
        span+span {
            font-size: 12px;
            margin-right: 10px;
        }
        span+span+span {
            width: 30px;
            height: 1px;
            background-color: #b9b9ca;
        }
    }
}

hr {
    background-color: #bccee2;
}

.modal-backdrop {
    opacity: 0 !important;
    visibility: hidden !important;
}

.text-left {
    text-align: left;
}

.password-container {
    position: relative;
    .toggle-password {
        display: block;
        position: absolute;
        right: 10px;
        top: 5px;
    }
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
    color: #171851;
    border: 1px solid var(--border-outline);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f5f8fd;
}

.k-input {
    font-size: 13px;
}

.k-widget.k-dropdown {
    width: 100%;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
    color: #171851;
    border: 1px solid var(--border-outline);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    background-color: #f5f8fd;
}

.k-multiselect .k-multiselect-wrap li.k-button,
.k-dropdowntree .k-multiselect-wrap li.k-button {
    float: left;
    background-color: var(--warning);
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
}

.k-dropdown .k-dropdown-wrap:hover,
.k-dropdowntree .k-dropdown-wrap:hover,
.k-dropdown .k-dropdown-wrap.k-state-hover,
.k-dropdowntree .k-dropdown-wrap.k-state-hover {
    border-color: var(--border-outline);
    background-color: #fff;
    ;
}

ion-chip.md {
    border-radius: 3px;
    font-size: 12px;
}

ion-datetime {
    line-height: 35px;
    padding: 0 10px;
    height: 49px;
    background-color: #fff;
    border: 1px solid #b2cafd;
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;
    align-items: center;
    width: calc(50% - 30px);
    text-align: center;
}

.picker-wrapper {
    border-radius: 20px !important;
    max-width: 320px !important;
    bottom: 20px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #b2cafd;
    -webkit-text-fill-color: #333;
    -webkit-box-shadow: 0 0 0px 1000px #f4f6fc inset;
    transition: background-color 5000s ease-in-out 0s;
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #ff4e00;
    border-color: #ff4e00;
}

.pop-up {
    display: none;
    position: absolute;
    bottom: 30px;
    font-size: 15px;
    background-color: #ffff;
    z-index: 9;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 1px 12.5px 0 rgba(0, 79, 250, 0.1);
    &.show {
        display: block;
    }
    .close-icon {
        text-align: right;
        padding-right: 13px;
        font-size: 18px;
        color: var(--ion-color-primary);
        margin: 5px 0 0;
        text-transform: uppercase;
        font-family: arial;
    }
}

:root {
    --secondary: #f5f8fd;
    --border-outline: #b2cafd;
    --blue: #004ffa;
    --warning: #ff4e00;
    --dark: #171851;
}

.navbar {
    padding: 15px 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: #171851;
}

.k-item {
    color: var(--warning);
    font-size: 13px;
}

.k-state-selected {
    background-color: var(--secondary) !important;
    color: var(--dark) !important;
    font-size: 13px;
}

.headline-text {
    padding-left: 70px;
    position: relative;
    margin-bottom: 20px;
    padding-right: 3em;
    .smiley-face {
        position: absolute;
        left: 0px;
        top: -10px;
        padding: 10px;
        background-color: #fff;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        font-size: 25px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.l-1 {
    line-height: 1;
}

.btn:not(.btn-sm) {
    border-radius: 2px;
    font-size: 12px;
    line-height: 30px;
    text-transform: capitalize;
}

.btn-sm {
    border-radius: 2px;
    font-size: 12px;
    text-align: left;
}

.btn-outline-warning {
    color: var(--warning);
    border-color: var(--warning);
    background-color: transparent;
    &:hover,
    &:focus {
        color: #fff;
        border-color: var(--warning);
        background-color: var(--warning);
    }
}

.btn-warning {
    color: #fff;
    border-color: var(--warning);
    background-color: var(--warning);
    &:hover,
    &:focus {
        color: var(--warning);
        border-color: var(--warning);
        background-color: transparent;
    }
}

a {
    color: var(--blue);
    font-size: 12px;
}

.left-image {
    display: block;
    margin: 0 auto;
    width: 400px;
}

body {
    background-color: var(--secondary);
}

p {
    font-size: 12px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e6f1fd;
    padding: 50px;
    border-radius: 5px;
}

.form-label {
    font-size: 11px;
    font-weight: 500 !important;
    margin: 0;
}

.input-group {
    .input-group-text {
        line-height: 35px;
        padding: 0 10px;
        height: 49px;
        background-color: #eaf1ff;
        border: 1px solid #b2cafd;
        font-size: 10px;
        width: 60px;
        font-weight: 800;
        text-align: center;
        justify-content: center;
        text-transform: uppercase;
    }
}

.form-control {
    border: 1px solid var(--border-outline);
    border-radius: 4px;
    font-size: 13px;
    line-height: 35px;
    margin-bottom: 10px;
    &.fill {
        background-color: #f5f8fd;
    }
}

.spacer {
    height: 30px;
}

.right-scrollable {
    max-height: 80vh;
    overflow-y: auto;
    padding-bottom: 70px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.stepper-footer {
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    button {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.stepper-breadcrumb {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .stepper-item {
        display: flex;
        align-items: center;
        &.active {
            .span {
                background-color: #ff4e00;
                color: #fff;
            }
            span+span {
                font-weight: 600;
            }
        }
        .span {
            background-color: #b9b9ca;
            width: 15px;
            height: 15px;
            font-size: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 5px;
        }
        span+span {
            font-size: 12px;
            margin-right: 10px;
        }
        span+span+span {
            width: 30px;
            height: 1px;
            background-color: #b9b9ca;
        }
    }
}

hr {
    background-color: #bccee2;
}

.modal-backdrop {
    opacity: 0 !important;
    visibility: hidden !important;
}

.text-left {
    text-align: left;
}

.password-container {
    position: relative;
    .toggle-password {
        display: block;
        position: absolute;
        right: 10px;
        top: 5px;
    }
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
    color: #171851;
    border: 1px solid var(--border-outline);
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f5f8fd;
}

.k-input {
    font-size: 13px;
}

.k-widget.k-dropdown {
    width: 100%;
}

.k-multiselect {
    .k-dropdown .k-dropdown-wrap,
    .k-dropdowntree .k-dropdown-wrap {
        color: #171851;
        border: 1px solid var(--border-outline);
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 10px;
        width: 100%;
        background-color: #f5f8fd;
    }
}

.k-multiselect .k-multiselect-wrap li.k-button,
.k-dropdowntree .k-multiselect-wrap li.k-button {
    float: left;
    background-color: var(--warning);
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
}

.k-dropdown .k-dropdown-wrap:hover,
.k-dropdowntree .k-dropdown-wrap:hover,
.k-dropdown .k-dropdown-wrap.k-state-hover,
.k-dropdowntree .k-dropdown-wrap.k-state-hover {
    border-color: var(--border-outline);
    background-color: #fff;
    ;
}

ion-chip.md {
    border-radius: 3px;
    font-size: 12px;
}

ion-datetime {
    line-height: 35px;
    padding: 0 10px;
    height: 49px;
    background-color: #fff;
    border: 1px solid #b2cafd;
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;
    align-items: center;
    width: calc(50% - 30px);
    text-align: center;
}

.picker-wrapper {
    border-radius: 20px !important;
    max-width: 320px !important;
    bottom: 20px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #b2cafd;
    -webkit-text-fill-color: #333;
    -webkit-box-shadow: 0 0 0px 1000px #f4f6fc inset;
    transition: background-color 5000s ease-in-out 0s;
}

body {
    overflow: hidden;
    background-color: var(--secondary);
}

.online-status-dot {
    background-color: green;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 10px;
    border-radius: 10px;
}

.container-section {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    height: 100vh;
    background-color: red;
    overflow: hidden;
    .left-sidebar {
        width: 250px;
        padding: 80px 20px;
        background-color: #fff;
        height: 100vh;
        overflow-y: auto;
        .left-menu-container {
            padding: 30px 0;
            .menu-container-heading {
                text-transform: uppercase;
                font-size: 11px;
                color: #999;
                font-weight: 700;
                border-bottom: 1px solid #ddd;
                line-height: 30px;
            }
            .menu-item-center {
                display: flex;
                align-items: center;
                height: 35px;
                color: #171851;
                font-weight: 600;
                text-decoration: none;
                padding: 0 10px;
                margin-bottom: 5px;
                border-radius: 3px;
                &:hover,
                &:focus {
                    background-color: #f8f8f8;
                }
                &.active {
                    background-color: #ff4e00;
                    color: #fff;
                    &:hover,
                    &:focus {
                        opacity: 0.5;
                    }
                    svg {
                        fill: #fff;
                        width: 16px;
                    }
                }
                svg {
                    font-size: 16px !important;
                    width: 16px;
                    margin-right: 10px;
                    fill: #bbb;
                }
            }
        }
    }
    .right-content-section {
        width: calc(100% - 250px);
        background-color: #f5f8fd;
        height: 100vh;
        overflow: hidden;
        padding-top: 120px;
        position: relative;
        .header-container {
            background-color: #fff;
            position: absolute;
            left: 0;
            right: 0;
            top: 70px;
            border: 1px solid #f5f8fd;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn-outline-secondary {
                border: 1px solid #b2cafd !important;
                padding-top: 0;
                padding-bottom: 0;
                &.show,
                &:focus,
                &:hover {
                    background-color: #b2cafd !important;
                    outline: none;
                    box-shadow: none;
                }
            }
            .dropdown-menu {
                color: #171851;
                border: 1px solid #b2cafd !important;
                padding: 0;
                .dropdown-item {
                    line-height: 30px;
                }
            }
            h6 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                svg {
                    color: #171851;
                    margin-right: 10px;
                }
            }
        }
        .content-container {
            overflow-y: auto;
            position: absolute;
            left: 10px;
            right: 10px;
            top: 155px;
            padding: 20px;
            bottom: 10px;
            background-color: #fff;
            &.grey-bg {
                background-color: #f5f8fd;
            }
        }
    }
}

.header-main-search-container {
    display: flex;
    width: calc(100% - 120px);
    justify-content: space-between;
    align-items: center;
    .search-input {
        background-color: #fff;
        color: #171851;
        border: 0px solid #f5f8fd;
        max-width: 350px;
        height: 37px;
        border: 1px solid #ccc;
        overflow: hidden;
        border-radius: 5px;
        .input-group-text {
            background-color: #fff;
            color: #171851;
            border: 0px solid #f5f8fd;
            color: #999;
            height: 35px;
        }
        input {
            background-color: #fff;
            color: #171851;
            border: 0px solid #f5f8fd;
            font-size: 12px;
            height: 35px;
            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }
    }
}

table {
    border: 0;
    th,
    td {
        padding: 4px 10px;
        border-style: solid;
        border-color: inherit;
        outline: 0;
        font-weight: inherit;
        text-align: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 0;
    }
    th {
        font-size: 12px;
        font-weight: 700;
    }
    td {
        font-size: 10px;
        font-weight: 400;
        padding: 4px 10px;
        a {
            font-size: inherit !important;
            color: var(--ion-color-primary) !important;
            font-weight: 500 !important;
            text-decoration: underline !important;
        }
    }
}

.modal-content {
    background-color: #f4f6fc !important;
}

.modal-header {
    background-color: #fff !important;
    .modal-title {
        font-size: 15px;
        font-weight: 700;
    }
}

.card-space-divider {
    height: 1px;
    background-color: #efefef;
    margin: 10px 0;
}

.detail-card {
    margin: 20px 0 10px;
    background-color: #fff;
    border-radius: 2px;
    position: relative;
    padding: 0px;
    .card-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #efefef;
        h3 {
            margin: 0;
            font-size: 15px;
            font-weight: 600;
        }
        span {
            margin: 0;
            font-size: 12px;
            font-weight: 700;
        }
    }
    .card-content {
        padding: 20px;
    }
    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 0px solid #efefef;
        background-color: #fff;
        h3 {
            margin: 0;
            font-size: 15px;
            font-weight: 700;
        }
        span {
            margin: 0;
            font-size: 15px;
            font-weight: 400;
        }
    }
}

.service-status-cell {
    text-transform: capitalize;
    font-size: 12px;
}

.service-status-button {
    border: 0;
    background-color: #e6f2fd;
    padding: 8px 15px;
    border-radius: 3px;
    color: #171851;
    font-size: 11px;
    margin-right: 10px;
    &.active {
        background-color: #00b1ff;
        color: #fff;
    }
    &.warning {
        color: #fff;
        background-color: #ff4e00;
    }
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #ff4e00;
    border-color: #ff4e00;
}

.pointer {
    cursor: pointer;
}

.upload-form-group {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    background-color: #fff;
    padding: 0px 0 20px;
    transition: all ease-in-out 0.5s;
    &.uploaded {
        background-color: #fff;
        transition: all ease-in-out 0.5s;
        h3 {
            color: var(--ion-color-primary);
            transition: all ease-in-out 0.5s;
            strong {
                font-weight: 900;
            }
        }
    }
    h3 {
        font-size: 12px;
        font-weight: 400;
        color: var(--ion-color-danger);
        margin: 0 0 0 20px;
        transition: all ease-in-out 0.5s;
    }
    &:hover {
        transition: all ease-in-out 0.5s;
        h3 {
            color: #171851 !important;
            transition: all ease-in-out 0.5s;
        }
    }
    &:focus {
        transition: all ease-in-out 0.5s;
        background-color: #fff;
        h3 {
            color: #171851 !important;
            transition: all ease-in-out 0.5s;
        }
    }
}

.flex-main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #efefef;
}

