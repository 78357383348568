img.inline-abs-image {
    margin: 0 20px 0 0;
}

.edit-btn {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 20px;
}

.card-body.p-0.d-flex.align-items-start div {
    width: 100%;
    padding-right: 20px;
}