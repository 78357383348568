.result-list {
  display: block;
  background-color: #fcf6ff;
  border-bottom: 1px solid #ebe9ec;
  cursor: pointer;
  &:nth-child(4) {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-child {
    border-bottom: 0px solid #ffffff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &:hover {
    opacity: 0.8;
  }
}

.workshop-combo.k-combobox {
  display: block;
  height: 48px;
  line-height: 38px;
}

.workshop-combo.k-combobox .k-input {
  height: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 38px;
  box-sizing: border-box;
}

.workshop-combo.k-combobox .k-select {
  background: transparent;
  border: none;
  height: 38px;
  line-height: 38px;
  position: relative;
  top: 0;
  right: 1.2rem;
}

.workshop-combo.k-combobox .k-dropdown-wrap {
  width: 100% !important;
  position: absolute;
}

.workshop-combo.k-combobox .k-clear-value {
  position: absolute;
  top: 0;
  right: 2.5rem;
}
