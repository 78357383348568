.modal {
    background: rgba(23, 24, 81, 0.85);
}

input.hidden-input {
    opacity: 0
}

.k-dropdown, .k-dropdowntree {
    padding: 0;
    background-color: #FFF;
    border: 0px;

}

.k-dropdown:hover, .k-dropdowntree:hover {
    padding: 0;
    background-color: #FFF;
    border: 0px;
}

