.onboarded-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff4e00;
    color: #fff;
    flex-direction: column;
    height: calc(100vh - 71px);
    img {
        width: 50px;
        margin-bottom: 50px;
    }
    div {
        max-width: 320px;
        h3 {
            color: #fff;
            font-size: 25px;
            margin-bottom: 30px;
        }
        h6 {
            color: #fff;
            font-size: 16px;
            margin-bottom: 15px;
        }
    }
}